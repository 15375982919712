<template>
  <v-layout row wrap>
    <v-flex xs12 md12>
      <v-card>
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-container grid-list-md>
            <v-layout row wrap>
              <v-flex xs2 md2>
                <v-text-field
                  :disabled="!!unidade.codigo"
                  :rules="campoRules"
                  placeholder=" "
                  outline
                  label="Cnpj"
                  id="cnpj"
                  name="cnpj"
                  type="text"
                  required
                  mask="##.###.###/####-##"
                  v-model="unidade.cnpj"
                  @blur="getReceitaWS(unidade.cnpj)"
                ></v-text-field>
              </v-flex>

              <v-flex xs4 md4>
                <v-text-field
                  :disabled="!!unidade.codigo"
                  :rules="campoRules"
                  placeholder=" "
                  outline
                  label="Razão Social"
                  id="razao_social"
                  name="razao_social"
                  type="text"
                  required
                  v-model="unidade.razao_social"
                  @input="$event => unidade.razao_social = $event.toUpperCase()"
                ></v-text-field>
              </v-flex>

              <v-flex xs4 md4>
                <v-text-field
                  :rules="campoRules"
                  placeholder=" "
                  outline
                  label="Nome Fantasia"
                  id="nome_fantasia"
                  name="nome_fantasia"
                  type="text"
                  required
                  v-model="unidade.nome_fantasia"
                  @input="$event => unidade.nome_fantasia = $event.toUpperCase()"
                ></v-text-field>
              </v-flex>

              <v-flex xs2 md2>
                <v-currency-field
                  placeholder=" "
                  outline
                  label="Comissão(%)"
                  id="comissao"
                  name="comissao"
                  :max="comissaoMaxima"
                  :min="0"
                  v-model="unidade.comissao"
                ></v-currency-field>
              </v-flex>
              <v-flex xs2 md2>
                <v-text-field
                  placeholder=" "
                  outline
                  label="Cep"
                  id="cep"
                  name="cep"
                  type="text"
                  required
                  mask="##.###-###"
                  v-model="unidade.cep"
                  @blur="getCep(unidade.cep)"
                ></v-text-field>
              </v-flex>

              <v-flex xs4 md4>
                <v-text-field
                  placeholder=" "
                  outline
                  label="Bairro"
                  id="bairro"
                  name="bairro"
                  type="text"
                  required
                  v-model="unidade.bairro"
                  @input="$event => unidade.bairro = $event.toUpperCase()"
                ></v-text-field>
              </v-flex>

              <v-flex xs4 md4>
                <v-text-field
                  v-model="unidade.logradouro"
                  placeholder=" "
                  outline
                  label="Logradouro"
                  id="logradouro"
                  name="logradouro"
                  type="text"
                  required
                  @input="$event => unidade.logradouro = $event.toUpperCase()"
                ></v-text-field>
              </v-flex>

              <v-flex xs2 md2>
                <v-text-field
                  placeholder=" "
                  outline
                  label="Número"
                  id="numero"
                  name="numero"
                  type="text"
                  required
                  v-model="unidade.numero"
                  @input="$event => unidade.numero = $event.toUpperCase()"
                ></v-text-field>
              </v-flex>

              <v-flex xs4 md4>
                <v-autocomplete
                  item-value="codigo"
                  item-text="nome"
                  v-model="unidade.estado_codigo"
                  :items="estados"
                  label="UF(Estado)"
                  placeholder=" "
                  outline
                  @change="changeState"
                  clearable
                  :return-object="false"
                ></v-autocomplete>
              </v-flex>

              <v-flex xs4 md4>
                <v-autocomplete
                  :disabled="!unidade.estado_codigo"
                  item-value="codigo"
                  item-text="nome"
                  v-model="unidade.cidade_codigo"
                  :items="cidades"
                  label="Cidade"
                  placeholder=" "
                  outline
                  clearable
                  :return-object="false"
                ></v-autocomplete>
              </v-flex>

              <v-flex xs4 md4>
                <v-text-field
                  placeholder=" "
                  outline
                  label="Complemento"
                  id="complemento"
                  name="complemento"
                  type="text"
                  required
                  v-model="unidade.complemento"
                  @input="$event => unidade.complemento = $event.toUpperCase()"
                ></v-text-field>
              </v-flex>

              <v-flex xs3 md3>
                <v-switch
                  :disabled="!autoriza_pag"
                  v-model="unidade.autoriza_pag"
                  :label="unidade.autoriza_pag ? 'Autoriza pagamento' : 'Não autoriza pagamento'"
                  color="green"
                ></v-switch>
              </v-flex>

              <v-flex xs8 md8></v-flex>

              <v-flex xs1 md1 v-if="!!unidade.codigo">
                <v-switch
                  v-if="!!unidade.codigo"
                  v-model="unidade.ativo"
                  :label="unidade.ativo ? 'Ativo' : 'Desativado'"
                  color="green"
                ></v-switch>
              </v-flex>

              <v-toolbar flat class="transparent">
                <v-spacer></v-spacer>
                <v-btn color="green" class="white--text" @click="saveUnidade(unidade)">Gravar</v-btn>
              </v-toolbar>
            </v-layout>
          </v-container>
        </v-form>
      </v-card>
    </v-flex>
  </v-layout>
</template>

<script>
const receitaModule = () => import('./../../services/receitaws')
const utilModule = () => import('./../../services/util')
const estadoModule = () => import('./../../services/estado')
const cidadeModule = () => import('./../../services/cidade')
const unidadeModule = () => import('./../../services/unidade')
const configModule = () => import('./../../services/configuracao')

export default {
  name: 'UnidadeCad',
  props: ['tipo'],
  data () {
    return {
      valid: true,
      config: {},
      comissaoMaxima: 0,
      autoriza_pag: true,
      unidade: {
        codigo: null,
        tipo: null,
        unidade_codigo: null,
        razao_social: null,
        nome_fantasia: null,
        cnpj: null,
        comissao: 0,
        cep: null,
        estado_codigo: null,
        cidade_codigo: null,
        logradouro: null,
        numero: null,
        bairro: null,
        complemento: null,
        autoriza_pag: false,
        ativo: true
      },
      emailRules: [
        v => !!v || 'E-mail é obrigatório.',
        v => /.+@.+/.test(v) || 'E-mail não é válido.'
      ],
      campoRules: [
        v => !!v || 'Campo é obrigatório.',
      ],
      estados: [],
      cidades: [],
    }
  },
  mounted () {
    if (this.$route.params.canal) {
      this.unidade.unidade_codigo = atob(this.$route.params.canal)
    }
    this.getByUnidade()
    this.getConfig()
    this.getComissaoCanal()
  },
  methods: {
    async voltar () {
      if (this.unidade.tipo === 'C') {
        this.$router.push('/canal')
      } else {
        this.$router.push(`/loja/${this.$route.params.canal}`)
      }
    },
    async getCep (cep) {
      let utilService = await utilModule()
      let resp = await utilService.getCep(cep)
      if (resp.status === 200 && resp.data) {
        this.unidade.logradouro = resp.data.logradouro
        this.unidade.complemento = resp.data.complemento
        this.unidade.bairro = resp.data.bairro
        let estado = this.estados.filter(estado => estado.sigla.toUpperCase() === resp.data.uf.toUpperCase())
        if (estado.length > 0) {
          this.unidade.estado_codigo = estado[0].codigo
          await this.getAllCidades(estado[0].codigo)
          let cidade = this.cidades.filter(c => c.nome.toUpperCase() === resp.data.localidade.toUpperCase())
          if (cidade.length > 0) {
            this.unidade.cidade_codigo = cidade[0].codigo
          }

        }
      }
    },
    async getAllEstados () {
      let estadoService = await estadoModule()
      let resp = await estadoService.getAll(this.$axios)
      if (resp.status === 200 && resp.data) {
        this.estados = resp.data
      }
    },
    async getAllCidades (estado_codigo) {
      let cidadeService = await cidadeModule()
      let resp = await cidadeService.getByEstado(this.$axios, estado_codigo)
      if (resp.status === 200 && resp.data) {
        this.cidades = resp.data
      }
    },
    async changeState (estado_codigo) {
      await this.getAllCidades(estado_codigo)
    },
    async getReceitaWS (cnpj) {
      let utilService = await utilModule()
      let cnpjValid = await utilService.validaCNPJ(cnpj)
      if (!cnpjValid) {
        this.$swal('CNPJ inválido.', '', 'error')
        return
      }
      let receitaService = await receitaModule()
      let resp = await receitaService.consultaReceita(cnpj)
      if (resp.status === 200 && resp.data && resp.data.nome) {
        this.unidade.razao_social = resp.data.nome
        this.unidade.nome_fantasia = (resp.data.fantasia !== null || resp.data.fantasia !== '') ? resp.data.fantasia : resp.data.nome
        this.unidade.cep = resp.data.cep.replace(/[^0-9]/g, '')
        this.unidade.bairro = resp.data.bairro
        this.unidade.logradouro = resp.data.logradouro
        this.unidade.numero = resp.data.numero
        await this.getCep(this.unidade.cep)
      }
    },
    async saveUnidade (unidade) {
      if (unidade.codigo) {
        await this.editUnidade(unidade)
      } else {
        await this.addUnidade(unidade)
      }
    },

    async addUnidade (unidade) {
      try {
        if (!this.$refs.form.validate()) {
          return
        }
        let utilService = await utilModule()
        let cnpjValid = await utilService.validaCNPJ(unidade.cnpj)
        if (!cnpjValid) {
          this.$swal('CNPJ inválido.', '', 'error')
          return
        }
        let unidadeService = await unidadeModule()
        let resp = await unidadeService.addUnidade(this.$axios, { ...unidade })
        if (resp.status === 200 && resp.data.success) {
          this.$toast.success('Sucesso ao incluir o registro')
          await this.voltar()
        } else {
          this.$swal('Não foi possível inserir o registro.', '', 'error')
        }
      } catch (error) {
        console.log(error)
      }
    },
    async editUnidade (unidade) {
      try {
        if (!this.$refs.form.validate()) {
          return
        }
        let utilService = await utilModule()
        let cnpjValid = await utilService.validaCNPJ(unidade.cnpj)
        if (!cnpjValid) {
          this.$swal('CNPJ inválido.', '', 'error')
          return
        }
        let unidadeService = await unidadeModule()
        let resp = await unidadeService.edtUnidade(this.$axios, { ...unidade })
        if (resp.status === 200 && resp.data.success) {
          this.$toast.success('Sucesso ao editar o registro')
          await this.voltar()
        } else {
          this.$swal('Não foi possível editar o registro.', '', 'error')
        }
      } catch (error) {
        console.log(error)
      }
    },
    async getComissaoCanal () {
      if (this.$route.params.canal) {
        let canal_codigo = atob(this.$route.params.canal)
        let unidadeService = await unidadeModule()
        let resp = await unidadeService.getByCodigo(this.$axios, canal_codigo)
        if (resp.status === 200 && resp.data) {
          this.comissaoMaxima = Number(resp.data.comissao)
          this.autoriza_pag = resp.data.autoriza_pag
        }
      }
    },
    async getByUnidade () {
      await this.getAllEstados()
      this.unidade.tipo = this.tipo
      if (this.$route.params.id) {
        let unidade_codigo = atob(this.$route.params.id)
        let unidadeService = await unidadeModule()
        let resp = await unidadeService.getByCodigo(this.$axios, unidade_codigo)
        if (resp.status === 200 && resp.data) {
          this.unidade.codigo = resp.data.codigo
          this.unidade.tipo = resp.data.tipo
          this.unidade.unidade_codigo = resp.data.unidade_codigo
          this.unidade.razao_social = resp.data.razao_social
          this.unidade.nome_fantasia = resp.data.nome_fantasia
          this.unidade.cnpj = resp.data.cnpj
          this.unidade.comissao = resp.data.comissao
          this.unidade.cep = resp.data.cep
          this.unidade.estado_codigo = resp.data.estado_codigo
          this.unidade.cidade_codigo = resp.data.cidade_codigo
          this.unidade.logradouro = resp.data.logradouro
          this.unidade.numero = resp.data.numero
          this.unidade.bairro = resp.data.bairro
          this.unidade.complemento = resp.data.complemento
          this.unidade.ativo = resp.data.ativo
          this.unidade.autoriza_pag = resp.data.autoriza_pag
          await this.getAllCidades(this.unidade.estado_codigo)
        }
      }
    },
    async getConfig () {
      let confiService = await configModule()
      let resp = await confiService.getConfig(this.$axios)
      if (resp.status === 200 && resp.data) {
        this.config = resp.data
        if (this.tipo === 'C') {
          this.comissaoMaxima = 100 - this.config.comissao_adm
        }
      }
    }

  }
}
</script>